import React from 'react';
import Layout from '../../components/layout';
import MessageForFailures from '../../components/careers/message-for-failures';
import Eyebrows from '../../components/complements/eyebrows';
import { PageProps } from 'gatsby';

const SEO = {
  'title': 'Application Error',
  'author': 'Deutsch LA',
  'keywords': 'Deutsch LA',
};

interface LocationState {
  error?: string;
}

const ApplicationError: React.FC<PageProps<any, any, LocationState>> = ({ location }) => {
  const { state } = location;
  return (
    <Layout seo={SEO}>
      <div className="max-width">
        <div className="border-top">
          <Eyebrows
            eyebrow1="Careers"
            eyebrow2=""
            firstEyebrowClass="careers-eyebrow-text-format orange-link"
            refEyebrow1="/careers"
          />
        </div>
        <div className="text-center">
          <MessageForFailures
            title={state?.error ?? 'Please try again'}
            message={state?.error ? '' : 'An error occurred while processing your application.'}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ApplicationError;
